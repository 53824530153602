/* eslint-disable react/jsx-filename-extension */
import React from "react"
import { Link } from "gatsby"
import * as cookiesWarningStyles from "./cookiesWarning.module.css"

export default class CookiesWarning extends React.Component {
  constructor(props) {
    super(props)
    this.state = { cookiesAccepted: true }

    this.acceptCookies = this.acceptCookies.bind(this)
  }

  componentDidMount() {
    this.setState({ cookiesAccepted: localStorage.getItem("cookiesAccepted") })
  }

  acceptCookies() {
    this.setState({ cookiesAccepted: true })
    localStorage.setItem("cookiesAccepted", true)
  }

  render() {
    return (
      <div>
        {this.state.cookiesAccepted ? null : (
          <div className={cookiesWarningStyles.cookiesContainer}>
            <span className={cookiesWarningStyles.cookiesText}>
              We use cookies to ensure that we give you the best experience on
              our website. To find out more, read our{" "}
              <Link to="/privacy-policy">privacy policy </Link>
              and <Link to="/cookies-policy">cookies policy</Link>.
            </span>

            <button className="buttonStyle2" onClick={this.acceptCookies}>
              Accept and Continue
            </button>
          </div>
        )}
      </div>
    )
  }
}
