import React from "react"
import loadable from "@loadable/component"
import SEO from "../components/seo"
import Header from "../components/header"
import ContactSection from "../components/contactSection"
import MailingList from "../components/mailingList"
import Footer from "../components/footer"
import CookiesWarning from "../components/cookiesWarning"

import * as layoutStyles from "./pageLayout.module.css"

export default function Layout(props) {
  const facebookAppId = process.env.MESSENGER_API
  const MessengerCustomerChat = loadable(() =>
    import("react-messenger-customer-chat")
  )

  return (
    <div className={layoutStyles.layout}>
      <SEO
        title={props.title}
        description={props.description}
        image={props.image}
        schemaMarkup={props.schema}
      />
      <CookiesWarning />
      <Header />
      <MessengerCustomerChat
        shouldShowDialog={false}
        minimized={false}
        title="Facebook customer chat"
        pageId="1522996417952337"
        appId={facebookAppId}
        themeColor="#ff6644"
        language="en_GB"
      />
      {props.children}
      <ContactSection />
      <MailingList />
      <Footer />
    </div>
  )
}

// Tintex pageID="1522996417952337"
